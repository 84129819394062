import axios from 'axios';

const instance = axios.create({
    // baseURL: 'https://d28f1ea282c4.ngrok.io/api/v1',
    baseURL: 'https://gateway.s5.ottomatically.com/api/v2',
    // const HOST = 'https://api.ottomatically.com/api/v2'; // for encryted data (Huawei)
    // baseURL: 'https://linked-things-s4-gateway.eu-de.mybluemix.net/api/v1',
    // baseURL: 'http://192.168.10.170:3000/api/v2',
    // baseURL: 'https://api.ottomatically.com/api/v2',
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
});
export default instance;

// Plateform 2.0 api
export const axios2_0 = axios.create({
    baseURL: 'https://gateway2.s5.ottomatically.com/api/v2',
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
});