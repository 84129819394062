import React, { Component } from "react";
import { getAqiDevices } from "../../shared/services/events";
import { HashLink as Link } from "react-router-hash-link";
import MinMaxBar from "../graphs/barGraph";
class AqiTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterDevices: [],
    };
  }
  componentWillMount() {
    if (
      this.props.aqiDevices &&
      this.props.dustDevices
    ) {
      let filterDevices = [],
        offlineDevies = []
      this.props.aqiDevices.map((device, index) =>
        this.props.dustDevices.map((d) => {
          if (device.hubId === d.hubId && device.dust === d.event.deviceId) {
            if (!(new Date(device.event.created) <= new Date(new Date() - (1000 * 60 * 60)))) {
              filterDevices.push({
                active: true,
                collapse: true,
                _id: device._id,
                location: device.location,
                name: device.level[0].name,
                created: device.event.created,
                dust: d.event.value.toFixed(0),
                aqi: device.event.value,
                coordinate: device.level[0].metadata.coordinates
              })
            } else {
              offlineDevies.push({
                active: false,
                collapse: true,
                _id: device._id,
                location: device.location,
                name: device.level[0].name,
                created: device.event.created,
                dust: d.event.value.toFixed(0),
                aqi: device.event.value,
                coordinate: device.level[0].metadata.coordinates
              })
            }
          }
        })
      )
      filterDevices = [...filterDevices, ...offlineDevies]
      this.setState({
        filterDevices,
      });
    }
  }

  tableColor = (value) => {
    const locationNm = this.props.locationTitleCase
    let tableColor = "",
      Colorvalue = parseInt(value)
    if (locationNm === 'Temperature') {
      let red = 0,
        green = 0,
        blue = 255;
      if (Colorvalue > 0 && Colorvalue <= 20) {
        var per = (Colorvalue / 20) * 100;
        red = 0;
        green = (255 * per) / 100;
        blue = 255 - ((255 * per) / 100);
      } else if (Colorvalue > 20 && Colorvalue <= 30) {
        var per = ((Colorvalue - 20) / 10) * 100;
        red = (255 * per) / 100;
        green = 255;
        blue = 0;
      } else if (Colorvalue > 30 && Colorvalue <= 40) {
        var per = ((Colorvalue - 30) / 10) * 100;
        red = 255;
        green = 255 - ((255 * per) / 100);
        blue = 0;
      } else if (Colorvalue > 40) {
        red = 255;
        green = 0;
        blue = 0;
      }
      tableColor = "rgb(" + red + "," + green + "," + blue + ")";
    } else if (locationNm === 'Humidity') {
      let red = 255,
        green = 0,
        blue = 0;
      if (Colorvalue > 0 && Colorvalue <= 40) {
        var per = (Colorvalue / 40) * 100;
        red = 255 - (255 * per) / 100;
        green = (255 * per) / 100;
        blue = (255 * per) / 100;
      } else if (Colorvalue > 40 && Colorvalue <= 60) {
        // var per = ((Colorvalue - 40) / 100) * 100;
        red = 0;
        green = 255;
        blue = 255;
      } else if (Colorvalue > 60 && Colorvalue <= 100) {
        var per = ((Colorvalue - 60) / 40) * 100;
        red = 0;
        green = 255 - ((255 * per) / 100);
        blue = 255;
      } else if (Colorvalue > 40) {
        red = 0;
        green = 0;
        blue = 255;
      }
      tableColor = "rgb(" + red + "," + green + "," + blue + ")";
    } else {
      let red = 0,
        green = 255,
        blue = 0;


      if (Colorvalue > 25 && Colorvalue <= 50) {
        var per = ((Colorvalue - 25) / 25) * 100;
        red = (230 * per) / 100;
        green = 150 + (80 * per) / 100;
        blue = 0;
      } else if (Colorvalue > 50 && Colorvalue <= 100) {
        var per = ((Colorvalue - 50) / 50) * 100;
        red = 230;
        green = 230 - (128 * per) / 100;
        blue = 0;
      } else if (Colorvalue > 100 && Colorvalue <= 150) {
        red = 200;
        green = 102 - (102 * (Colorvalue - 100)) / 100;
        blue = 0;
      } else if (Colorvalue > 150 && Colorvalue <= 200) {
        var per = ((Colorvalue - 150) / 50) * 100;
        red = 200 - ((100 * per) / 100);
        green = 0;
        blue = (100 * per) / 100;
      } else if (Colorvalue > 200 && Colorvalue <= 300) {
        var per = ((Colorvalue - 200) / 100) * 100;
        red = 100;
        //console.log("red", red)
        green = 0;
        blue = 100 - ((70 * per) / 100);
        //console.log("blue", blue)
      } else if (Colorvalue > 300) {
        red = 100;
        green = 0;
        blue = 30;
      }
      tableColor = "rgb(" + red + "," + green + "," + blue + ")";
    }


    return tableColor;
  };

  render() {
    return (
      <div>
        <div
          className="col-12 bg-dark text-light text-center"
          style={{ fontSize: "12px" }}
        >
          <b>Live Update</b>
        </div>
        <div
          className="col-12 d-flex p-2 align-items-center"
          style={{
            fontWeight: "bold",
            height: "50px",
            boxShadow: "0 5px 10px -5px rgba(0,0,0,0.1)",
          }}
        >
          <span className="col p-2" style={{ width: "60%" }}>
            Location
          </span>
          {this.props.locationTitleCase === '' || this.props.locationTitleCase === 'Air Quality' ?
            <span className="py-2 align-items-center mr-1 position-relative" style={{ width: "15%", lineHeight: "16px" }}>
              PM2.5{" "}
              <span style={{ fontSize: "10px", lineHeight: "10px", position: "absolute", left: 6, bottom: -5, }}>
                (µg/m<sup>3</sup>)
              </span>
            </span>
            : null}
          <span className="p-2 text-center" style={{ width: this.props.locationTitleCase === '' || this.props.locationTitleCase === 'Air Quality' ? "15%" : "30%" }}>
            {this.props.locationTitleCase === 'Temperature' ? 'Temperature °C' : this.props.locationTitleCase === 'Humidity' ? "Humidity %" : 'AQI'}
          </span>
        </div>
        <div style={{ overflowY: "auto", height: "calc(100vh - 160px)" }}>
          <div className="d-table p-2 w-100">
            {
              this.state.filterDevices.map((data, index) =>
                <React.Fragment key={index}>
                  <div className="col-12 w-100 d-table-row" onClick={() => {
                    let filterDevices = this.state.filterDevices
                    filterDevices[index]['collapse'] = !data.collapse;
                    this.setState({ filterDevices });
                    this.props.mapNavigation(data.coordinate)
                  }}>
                    <span
                      className="d-table-cell py-2"
                      style={{
                        borderBottom: "1px solid #00000015",
                        width: "75%",
                        fontWeight: "bold",
                        lineHeight: "14px"
                      }}
                    >
                      {index + 1}.
                      {" " + data.name + " "}
                      <small>
                        ({data.location})
                      </small>
                    </span>
                    {data.active ?
                      <React.Fragment>
                        {this.props.locationTitleCase === '' || this.props.locationTitleCase === 'Air Quality' ?
                          <span
                            className="d-table-cell p-2 bg-light text-center"
                            style={{
                              borderBottom: "1px solid #00000015",
                              width: "10%",
                            }}
                          >
                            {data.dust}
                          </span>
                          : null}
                        <span
                          className="d-table-cell p-2"
                          style={{
                            borderBottom: "1px solid #00000015",
                            width: "15%",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: this.tableColor(
                                data.aqi
                              ),
                              borderRadius: "30px",
                              textAlign: "center",
                              fontSize: "14px",
                              color: (() => {
                                const title = this.props.locationTitleCase || "Air Quality";
                                if (title === "Temperature" && (data.aqi > 35 || data.aqi < 6)) {
                                  return "white";
                                } else if (title === "Humidity" && (data.aqi > 75 || data.aqi < 25)) {
                                  return "white";
                                } else if (title === "Air Quality" && (data.aqi || 0) > 150) {
                                  return "white";
                                }
                                return "black";
                              })()

                            }}
                          >
                            {data.aqi}
                          </div>
                        </span>
                      </React.Fragment>
                      :
                      <td className="pl-2 py-3 border-0"
                        colspan="2"
                        style={{
                          borderBottom: "1px solid #00000015",
                          width: "10%",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "rgb(128, 128, 128)",
                            borderRadius: "30px",
                            textAlign: "center",
                            fontSize: "10px",
                            color: "white",
                          }}
                        >OFFLINE</div>
                      </td>}
                    <span className="d-table-cell"><i className={data.collapse ? "fa fa-angle-down" : "fa fa-angle-up"}></i></span>
                  </div>
                  {!data.collapse ?
                    <div className="col-12 d-table-row" >
                      <td colspan="4">
                        <MinMaxBar
                          _id={data._id}
                          type="Device"
                          title={data.name}
                        />
                      </td>
                    </div>
                    : null}
                </React.Fragment>
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

export default AqiTable;
