import React from 'react';

const CustomSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="150px"
    height="30px"
    version="1.1"
    viewBox="0 0 5786 1887"
    style={{
      shapeRendering: 'geometricPrecision',
      textRendering: 'geometricPrecision',
      imageRendering: 'optimizeQuality',
      fillRule: 'evenodd',
      clipRule: 'evenodd',
    }}
  >
    <defs>
      <style type="text/css">
        {`
          .fil1 {fill:#1B75BC}
          .fil3 {fill:#F15A29}
          .fil2 {fill:#1B75BC;fill-rule:nonzero}
          .fil4 {fill:#BCBEC0;fill-rule:nonzero}
          .fil0 {fill:#F15A29;fill-rule:nonzero}
        `}
      </style>
    </defs>
    <g id="Layer_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer" />
      <g id="_904606472">
        <g>
          <path
            className="fil0"
            d="M2175 1072c82,0 156,34 210,88 54,53 87,128 87,210 0,82 -33,156 -87,210 -54,54 -128,87 -210,87 -82,0 -157,-33 -210,-87 -54,-54 -88,-128 -88,-210 0,-34 6,-67 17,-98l-599 -241 -714 269c9,28 14,58 14,89 0,83 -33,157 -87,211 -54,54 -128,87 -211,87 -82,0 -156,-33 -210,-87 -54,-54 -87,-128 -87,-211 0,-82 33,-156 87,-210 54,-54 128,-87 210,-87 83,0 157,33 211,87 16,17 31,36 43,56l719 -271 216 -420c-5,-5 -10,-9 -15,-14 -54,-54 -87,-128 -87,-210 0,-83 33,-157 87,-211 54,-54 128,-87 210,-87 82,0 156,33 210,87 54,54 87,128 87,211 0,82 -33,156 -87,210 -54,54 -128,87 -210,87 -49,0 -95,-12 -135,-33l-200 390 579 234c12,-18 25,-34 40,-48 53,-54 128,-88 210,-88zm-1718 158c-41,-41 -97,-66 -160,-66 -62,0 -118,25 -159,66 -41,41 -66,97 -66,159 0,63 25,119 66,160 41,41 97,66 159,66 63,0 119,-25 160,-66 41,-41 66,-97 66,-160 0,-62 -25,-118 -66,-159zm1383 -1070c-40,-41 -97,-66 -159,-66 -62,0 -119,25 -159,66 -41,41 -66,97 -66,160 0,62 25,118 66,159 40,41 97,66 159,66 62,0 119,-25 159,-66 41,-41 66,-97 66,-159 0,-63 -25,-119 -66,-160zm494 1050c-41,-40 -97,-66 -159,-66 -62,0 -119,26 -160,66 -40,41 -66,98 -66,160 0,62 26,118 66,159 41,41 98,66 160,66 62,0 118,-25 159,-66 41,-41 66,-97 66,-159 0,-62 -25,-119 -66,-160z"
          />
          <circle className="fil1" cx="1681" cy="320" r="152" />
          <circle className="fil1" cx="819" cy="688" r="152" />
          <circle className="fil1" cx="1181" cy="1523" r="152" />
        </g>
        <polygon
          className="fil2"
          points="3338,713 2996,713 2996,0 3068,0 3068,628 3338,628"
        />
        <path
          className="fil2"
          d="M3502 713l-92 0 0 -516 92 0 0 516zm0 -614l-92 0 0 -99 92 0 0 99z"
        />
        <path
          className="fil2"
          d="M4090 713l-93 0 0 -314c0,-53 -10,-89 -30,-109 -20,-19 -48,-29 -84,-29 -27,0 -53,6 -78,19 -24,12 -42,30 -52,53 -10,23 -15,56 -15,98l0 282 -93 0 0 -516 83 0 0 73 2 0c20,-28 44,-49 73,-63 28,-15 61,-22 99,-22 29,0 57,5 85,15 29,10 51,24 67,44 16,19 26,40 30,62 4,22 6,52 6,89l0 318z"
        />
        <polygon
          className="fil2"
          points="4668,713 4554,713 4372,449 4307,508 4307,713 4214,713 4214,0 4307,0 4307,406 4526,197 4646,197 4438,388"
        />
        <path className="fil2" d="M5171 477l-410 0c4,57 22,101 52,131 31,30 69,45 115,45 36,0 65,-9 89,-26 24,-18 43,-45 55,-80l95 11c-15,54 -43,95 -84,124 -42,29 -93,43 -155,43 -82,0 -146,-24 -192,-71 -46,-48 -69,-112 -69,-195 0,-81 22,-147 67,-198 45,-50 108,-76 189,-76 40,0 78,9 115,25 37,17 68,45 94,85 26,39 39,100 39,182zm-96 -72c-3,-52 -21,-90 -51,-113 -31,-24 -65,-35 -101,-35 -44,0 -79,14 -107,41 -29,28 -45,63 -49,107l308 0z"/>
   <path className="fil2" d="M5784 713l-98 0 0 -66 -3 0c-38,52 -95,78 -171,78 -77,0 -140,-25 -191,-75 -51,-50 -77,-115 -77,-195 0,-83 24,-149 72,-197 48,-48 112,-73 191,-73 74,0 131,24 169,71l3 0 0 -256 105 0 0 713zm-261 -60c46,0 85,-15 117,-47 32,-31 48,-79 48,-143 0,-63 -15,-113 -44,-150 -29,-38 -71,-56 -126,-56 -55,0 -96,18 -124,55 -28,36 -42,84 -42,143 0,43 8,79 23,109 15,30 36,52 63,67 27,15 55,22 85,22z"/>
   <polygon className="fil0" points="3211,1657 3211,926 2964,926 2964,869 3516,869 3516,926 3269,926 3269,1657 "/>
   <path className="fil0" d="M3598 1657l0 -788 57 0 0 317c25,-31 53,-60 83,-76 168,-86 282,69 282,230l0 317 -57 0 0 -289c0,-137 -73,-299 -231,-200 -30,19 -55,52 -77,88l0 401 -57 0z"/>
   <polygon className="fil0" points="4144,1657 4144,1092 4203,1092 4203,1657 "/>
   <path className="fil0" d="M4308 1657l0 -552 56 0 0 89c25,-33 51,-58 80,-74 30,-16 61,-24 96,-24 58,0 102,19 133,58 31,39 47,95 47,167l0 336 -56 0 0 -307c0,-66 -11,-116 -33,-149 -21,-33 -53,-49 -97,-49 -35,0 -67,10 -95,29 -29,20 -54,49 -75,87l0 389 -56 0z"/>
   <path className="fil0" d="M5444 1557c28,19 55,32 82,41 27,9 55,14 83,14 35,0 63,-9 83,-25 20,-17 31,-40 31,-69 0,-43 -37,-77 -111,-105 -19,-7 -34,-12 -45,-16 -41,-16 -71,-37 -91,-62 -21,-25 -31,-55 -31,-90 0,-45 16,-81 49,-109 32,-29 74,-43 126,-43 24,0 48,4 73,12 25,7 50,19 77,35l0 63c-28,-18 -55,-31 -80,-40 -25,-9 -50,-14 -74,-14 -33,0 -60,9 -81,27 -21,18 -31,40 -31,68 0,24 9,45 29,61 19,17 55,34 107,52 56,20 94,41 115,64 21,23 31,53 31,91 0,48 -16,87 -48,115 -33,28 -78,42 -134,42 -29,0 -56,-4 -82,-12 -26,-8 -52,-20 -78,-36l0 -64z"/>
   <circle className="fil3" cx="4174" cy="913" r="31"/>
   <path className="fil0" d="M4982 1471c-5,-1 -10,-1 -15,1 -5,1 -10,5 -13,9 -3,5 -5,10 -5,15 0,6 2,11 5,16 3,4 8,8 13,9 2,1 4,1 5,1 20,-4 40,-6 61,-6 63,0 120,20 162,52 44,34 71,81 71,133 0,53 -27,100 -71,134 -42,32 -99,52 -162,52 -63,0 -120,-20 -162,-52 -44,-34 -71,-81 -71,-134 0,-52 27,-99 71,-133 11,-9 24,-17 38,-24l0 0c-11,-14 -16,-31 -16,-48 0,-16 5,-33 16,-47 3,-5 7,-10 12,-13 -3,-3 -6,-5 -9,-7 -42,-39 -69,-91 -69,-150 0,-58 27,-111 69,-149 42,-37 100,-60 163,-60 34,0 66,6 95,18 65,0 131,0 196,0 -10,20 -35,42 -57,56l-57 0c34,36 55,84 55,135 0,59 -27,111 -69,150 -42,37 -100,60 -163,60 -33,0 -65,-6 -93,-18zm219 -299c-32,-29 -77,-46 -126,-46 -49,0 -94,17 -126,46 -31,27 -50,65 -50,107 0,42 19,80 50,108 32,28 77,46 126,46 49,0 94,-18 126,-46 31,-28 50,-66 50,-108 0,-42 -19,-80 -50,-107zm-201 402l0 1c-7,2 -14,3 -21,3 -29,7 -54,19 -74,34 -31,24 -49,55 -49,89 0,35 18,66 49,90 32,24 78,40 128,40 51,0 96,-16 128,-40 31,-24 49,-55 49,-90 0,-34 -18,-65 -49,-89 -32,-25 -77,-40 -128,-40 -11,0 -23,1 -33,2z"/>
   <circle className="fil3" cx="5524" cy="448" r="82"/>
   <ellipse className="fil1" cx="5073" cy="1263" rx="94" ry="74"/>
   <polygon className="fil4" points="2784,21 2784,1653 2736,1653 2736,21 "/>
        {/* Continue converting each element in the same way */}
      </g>
    </g>
  </svg>
);

export default CustomSVG;
