import React from 'react'

const LoaderSVG = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlSpace="preserve"
        version="1.1"
        style={{
            shapeRendering: "geometricPrecision",
            textRendering: "geometricPrecision",
            imageRendering: "optimizeQuality",
            fillRule: "evenodd",
            clipRule: "evenodd",
        }}
        viewBox="0 0 47379 51337"
    >
        <defs>
            <style type="text/css">
                {`
          .mainsvg {
            transform: scale(0.7);
            transform-origin: center;
          }
          .fil3 { fill: #1B75BC }
          .fil0 { fill: #1B75BC; fill-rule: nonzero }
          .fil2 { fill: #f15a29; fill-rule: nonzero }
          .fil1 { fill: #f15a29; fill-rule: nonzero }
          .level1, .level2, .level3, .level4 {
            animation: anticlock infinite linear;
            transform-origin: center;
          }
          .level1 { animation-duration: 1s; animation-direction: reverse; }
          .level2 { animation-duration: 3s; }
          .level3 { animation-duration: 5s; animation-direction: reverse; }
          .level4 { animation-duration: 7s; }
          @keyframes anticlock {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
            </style>
        </defs>
        <g>
            <g className="mainsvg">
                <path className="fil3" d="M26676 25586c0,1620 -1313,2933 -2933,2933 -1620,0 -2933,-1313 -2933,-2933 0,-1619 1313,-2932 2933,-2932 1620,0 2933,1313 2933,2932z" />
                <path className="fil2 level1" d="M19614 32742c99,57 133,184 76,283 -57,99 -184,133 -284,76 -1164,-671 -2157,-1603 -2900,-2716 -728,-1089 -1216,-2351 -1393,-3710 -15,-114 66,-218 179,-233 114,-15 218,65 233,179 168,1296 633,2498 1326,3534 707,1059 1653,1947 2763,2587zm8541 -14238c-97,-60 -127,-188 -66,-285 60,-97 188,-127 285,-67 1097,686 2020,1604 2711,2674 686,1065 1144,2280 1315,3567 15,114 -64,218 -178,233 -113,16 -218,-64 -233,-178 -163,-1226 -599,-2384 -1253,-3398 -658,-1019 -1537,-1893 -2581,-2546z" />
                <g className="level2">
                    <path className="fil0" d="M37180 27413c39,-292 307,-496 598,-457 291,39 495,307 456,598 -291,2146 -1057,4186 -2217,5978 -1152,1780 -2692,3314 -4538,4461 -250,156 -578,79 -734,-171 -155,-249 -79,-578 171,-733 1714,-1065 3142,-2487 4209,-4137 1075,-1660 1785,-3550 2055,-5539zm-26872 -3157c-29,292 -289,506 -582,477 -292,-29 -506,-289 -477,-581 224,-2241 963,-4398 2148,-6296 1161,-1858 2752,-3472 4707,-4678 250,-154 578,-77 732,173 154,250 77,577 -173,731 -1811,1117 -3284,2613 -4361,4337 -1101,1762 -1787,3762 -1994,5837z" />
                    <path className="fil2" d="M22142 12840c411,411 977,665 1601,665l0 -2c625,0 1191,-253 1601,-663 411,-411 665,-977 665,-1601l-2 0c0,-625 -254,-1191 -663,-1601 -411,-411 -977,-665 -1601,-665l0 2c-625,0 -1191,254 -1601,663 -410,410 -663,976 -663,1601 0,625 253,1191 663,1601zm0 28694c410,410 976,663 1601,663 625,0 1191,-253 1601,-663 409,-409 663,-975 663,-1601 0,-625 -254,-1191 -663,-1601 -410,-409 -976,-663 -1601,-663 -625,0 -1191,254 -1601,663 -410,410 -663,976 -663,1601 0,626 253,1192 663,1601zm1601 1775c-932,0 -1776,-378 -2387,-988 -611,-611 -989,-1455 -989,-2388 0,-932 378,-1776 989,-2387 611,-611 1455,-989 2387,-989 932,0 1776,378 2387,989 611,611 989,1455 989,2387 0,933 -378,1777 -989,2388 -611,610 -1455,988 -2387,988zm0 -28696c-934,0 -1778,-377 -2387,-987 -611,-611 -989,-1455 -989,-2387 0,-932 378,-1776 989,-2387 611,-611 1455,-989 2387,-989l0 2c933,0 1777,378 2387,987 611,611 989,1455 989,2387l-2 0c0,934 -377,1778 -987,2387 -611,611 -1455,989 -2387,989l0 -2z" />
                </g>
                <path className="fil3 level3" d="M43201 24290c716,-716 1877,-716 2593,0 716,716 716,1877 0,2593 -716,716 -1877,716 -2593,0 -716,-716 -716,-1877 0,-2593zm-37499 -7015c-180,393 -644,566 -1037,386 -393,-180 -565,-645 -385,-1038 1331,-2894 3286,-5435 5686,-7457 2386,-2010 5211,-3507 8301,-4329 418,-111 848,138 959,556 111,418 -137,847 -555,958 -2868,762 -5488,2150 -7699,4013 -2224,1873 -4036,4229 -5270,6911zm36452 15981c166,-399 624,-588 1023,-422 399,166 588,624 422,1023 -1277,3057 -3243,5774 -5726,7935 -2432,2116 -5362,3703 -8632,4561 -417,109 -845,-140 -954,-558 -110,-417 139,-845 557,-955 3027,-793 5743,-2265 7998,-4228 2306,-2007 4129,-4525 5312,-7356zm-40114 -8966c716,-716 1877,-716 2593,0 716,716 716,1877 0,2593 -716,716 -1877,716 -2593,0 -716,-716 -716,-1877 0,-2593z" />
                <path className="fil1 level4" d="M29354 1889c-514,-122 -832,-638 -710,-1153 122,-514 638,-832 1153,-710 3790,905 7306,2643 10306,5039 2982,2382 5453,5413 7177,8925 234,475 38,1049 -437,1283 -475,233 -1049,37 -1283,-438 -1599,-3258 -3891,-6070 -6654,-8277 -2780,-2220 -6038,-3830 -9552,-4669zm-10458 47552c519,104 855,608 751,1127 -104,518 -608,854 -1127,750 -3973,-801 -7702,-2510 -10889,-4973 -3119,-2411 -5724,-5547 -7534,-9265 -232,-474 -34,-1047 440,-1278 475,-232 1048,-35 1279,440 1676,3442 4090,6348 6985,8585 2959,2287 6416,3872 10095,4614z" />
            </g>
        </g>
    </svg>

)
export default LoaderSVG
