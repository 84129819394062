import React, { useEffect, useRef, useState } from 'react';
import { Chart, platform } from 'chart.js';
import LoaderSVG from './Loader/LoaderSVG';
import './Weather.css'
import { getLegacyWeatherData, getPlatformWeatherData } from '../../../shared/services/events';
import ChartConfig from './graph/chartConfig';
import { idGenerator } from '../../../functions';

const WeatherChart = ({ minMax, selectedCity }) => {

  const [DTR_Graph, setDTR_Graph] = useState({
    loader: true,
    graphData: null,
    DT_GraphData: null,
    graphConfig: {
      id: idGenerator() + "Graph",
      axisX: "date",
      axisY: "value",
      multipleValueAxes: true,
      // scrollbar: true,
      series: [
        {
          type: "line",
          name: "Temperature",
          color: "rgba(140, 0, 0, 0.2)",
          x: "date",
          y: "temperature",
          unit: "°C",
          // customTooltip: "{name}\n[bold font-size: 20]{valueY}[/] C",
        },
        {
          type: "line",
          name: "Humidity",
          color: "rgba(0, 0, 128, 0.2)",
          x: "date",
          y: "humidity",
          unit: "%",
          // customTooltip: "{name}\n[bold font-size: 20]{valueY}[/] %",
        },
      ],
    },
    // DT_graphConfig: {
    //   id: idGenerator() + "DT_Graph",
    //   unit: " min",
    //   axisX: "date",
    //   axisY: "value",
    //   scrollbar: true,
    //   series: [
    //     {
    //       type: "bar",
    //       name: "Total Downtime",
    //       color: "#008df2",
    //       x: "created",
    //       y: "totalDT",
    //     },
    //   ],
    // },
    // search: {
    //   startDate: todayDate("start"),
    //   endDate: todayDate("end"),
    // }
  })
  const chartRef = useRef(null); // Reference to the canvas
  const [tempData, setTempData] = useState([]);
  const [humiData, setHumiData] = useState([]);
  var currentDate = new Date();

  var year = currentDate.getFullYear();
  var month = currentDate.getMonth() + 1;
  var day = currentDate.getDate();

  // Format the date as YYYY-MM-DD
  var formattedDate = year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);

  // if (selectedCity.platform == "1.0") {
  //   platform_tempApi = `https://gateway.s5.ottomatically.com/api/v1/devices/${selectedCity.id}_Temperature/events/temp/stats?type=Agri_Temperature_Status&filter=hourly&endDate=${formattedDate}%2023:59:59`
  //   platform_humApi = `https://gateway.s5.ottomatically.com/api/v1/devices/${selectedCity.id}_Humidity/events/temp/stats?type=Agri_Humidity_Status&filter=hourly&endDate=${formattedDate}%2023:59:59`
  // } else {
  //   platform_tempApi = `https://gateway2.s5.ottomatically.com/api/v1/devices/${selectedCity.id}_Temperature/events/temp/stats?filter=hourly&value=value&endDate=${formattedDate}%2023:59:59`
  //   platform_humApi = `https://gateway2.s5.ottomatically.com/api/v1/devices/${selectedCity.id}_Humidity/events/temp/stats?filter=hourly&value=value&endDate=${formattedDate}%2023:59:59`

  // }
  // //console.log('platform_tempApi: ', platform_tempApi);

  let min_max = {}
  async function fetchAndRenderWeatherData() {
    try {
      if (selectedCity.platform == "1.0") {
        const response = await getLegacyWeatherData(selectedCity.id, "Temperature", formattedDate)
        let min = { value: null, created: null }
        let max = { value: null, created: null }
        response.map(res => {
          if (min.value === null) {
            min = { value: res.min, created: res.minDate }
          } else {
            if (min.value > res.min) {
              min = { value: res.min, created: res.minDate }
            }

          }
        }

        )
        response.map(res => {
          if (max.value === null) {
            max = { value: res.max, created: res.maxDate }
          } else {
            if (max.value < res.max) {
              max = { value: res.max, created: res.maxDate }
            }

          }
        })
        min_max = { min: min, max: max }
        minMax(min_max)
        // const data = response.data;
        return response
      } else {
        const response = await getPlatformWeatherData(selectedCity.id, "Temperature", formattedDate)
        let min = { value: null, created: null }
        let max = { value: null, created: null }
        for (const res of response) {
          if (min.value === null) {
            min = { value: res.min, created: res.minDate }
          } else {
            if (min.value > res.min) {
              min = { value: res.min, created: res.minDate }
            }
          }
          if (max.value === null) {
            max = { value: res.max, created: res.maxDate }
          } else {
            if (max.value < res.max) {
              max = { value: res.max, created: res.maxDate }
            }

          }
        }
        min_max = { min: min, max: max }
        minMax(min_max)
        // const data = response.data;
        return response
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  async function fetchAndRenderHumidityData() {
    try {
      if (selectedCity.platform == "1.0") {
        const response = await getLegacyWeatherData(selectedCity.id, "Humidity", formattedDate)
        //console.log(response)
        // const data = response.data
        return response
      } else {
        const response = await getPlatformWeatherData(selectedCity.id, "Humidity", formattedDate)
        //console.log(response)
        // const data = response.data;
        return response
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }




  useEffect(() => {
    // Function to fetch and render weather data
    const fetchWeatherData = async () => {
      setTempData([])
      setHumiData([])
      const tempDataResponse = await fetchAndRenderWeatherData();
      //console.log('tempDataResponse: ', tempDataResponse);
      const humiDataResponse = await fetchAndRenderHumidityData();
      //console.log('humiDataResponse: ', humiDataResponse);


      if (tempDataResponse) {
        setTempData(tempDataResponse.reverse());
        //console.log('tempData after reverse', tempData)
      }
      if (humiDataResponse) {
        setHumiData(humiDataResponse.reverse());
      }

    };

    fetchWeatherData();
    const intervalId = setInterval(fetchWeatherData, 3000000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, [selectedCity.id]);

  useEffect(() => {
    setDTR_Graph({ ...DTR_Graph, loader: true });

    if (humiData.length && tempData.length) {
      // Take the last 12 entries from both tempData and humiData
      const recentTempData = tempData.slice(-12);
      const recentHumiData = humiData.slice(-12);


      const combinedData = recentTempData.map(tempEntry => {
        // Find the matching humidity entry by the hour
        const humidityEntry = recentHumiData.find(
          h => new Date(h.created).getHours() === new Date(tempEntry.created).getHours()
        );

        return {
          date: new Date(tempEntry.created.split("Z")[0]), // Convert date to Date object
          temperature: tempEntry.avg,       // Temperature value
          humidity: humidityEntry ? humidityEntry.avg : null, // Matching humidity or null
        };
      });

      setDTR_Graph({ ...DTR_Graph, graphData: combinedData, loader: false });
    }
  }, [humiData, tempData]);

  // const ctx = chartRef.current.getContext('2d');

  // // Destroy previous chart instance if exists
  // if (window.weatherChart && typeof window.weatherChart.destroy === 'function') {
  //   window.weatherChart.destroy();
  // }
  // const labels = tempData.map(item => {
  //   let options = { timeZone: 'Asia/Karachi', hour12: true, hour: '2-digit', minute: '2-digit' };
  //   let d = new Date(item.created);
  //   d.setHours(d.getHours() - 5);
  //   return d.toLocaleTimeString('en-US', options);
  // }).slice(-12);




  // // Create a new chart
  // window.weatherChart = new Chart(ctx, {
  //   type: 'line',
  //   data: {
  //     labels: labels,
  //     datasets: [
  //       {
  //         label: 'Temperature (°C)',
  //         data: tempValues,
  //         backgroundColor: 'rgba(140, 0, 0, 0.2)', // Adjusted for transparency
  //         borderColor: 'rgba(150, 0, 0, 1)',
  //         borderWidth: 1,
  //       },
  //       {
  //         label: 'Humidity (%)',
  //         data: humiValues,
  //         backgroundColor: 'rgba(0, 0, 128, 0.2)', // Adjusted for transparency
  //         borderColor: 'rgba(0, 0, 128, 1)',
  //         borderWidth: 1
  //       }
  //     ]
  //   },
  //   options: {
  //     plugins: {
  //       legend: {
  //         labels: {
  //           color: 'black',  // Set legend color to black
  //           font: {
  //             size: 14,  // Set legend font size
  //             family: 'Arial', // Optional: Set font family for better control
  //             weight: 'bold'  // Optional: Make legend labels bold
  //           }
  //         }
  //       }
  //     },
  //     responsive: true,
  //     maintainAspectRatio: false,
  //     scales: {
  //       x: {
  //         beginAtZero: true,
  //         ticks: {
  //           color: 'black', // Set X axis tick color to black
  //           font: {
  //             size: 14,  // Set X axis tick font size
  //             family: 'Arial' // Optional: Set font family
  //           }
  //         }
  //       },
  //       y: {
  //         beginAtZero: true,
  //         ticks: {
  //           font: {
  //             size: 14,  // Set Y axis tick font size
  //             family: 'Arial'  // Optional: Set font family
  //           },
  //           color: 'black',  // Set Y axis tick color to black
  //         }
  //       }
  //     }
  //   },
  //   scales: {
  //     y: {
  //       type: 'linear',
  //       display: true,
  //       position: 'left',
  //     },
  //     y1: {
  //       type: 'linear',
  //       display: true,
  //       position: 'right',

  //       // grid line settings
  //       grid: {
  //         drawOnChartArea: false, // only want the grid lines for one axis to show up
  //       },
  //     }
  //   }
  // });

  // // Cleanup function to destroy the chart when the component unmounts
  // return () => {
  //   if (window.weatherChart && typeof window.weatherChart.destroy === 'function') {
  //     window.weatherChart.destroy();
  //   }
  // };
  if (!tempData && !humiData) return <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div style={{ height: '100px', width: '100px', display: 'flex' }} ><LoaderSVG /></div></div>;
  return (
    // {/* {tempData.length&&humiData.length ? */}
    <div className="chart-container" >
      {
        DTR_Graph.loader ? <LoaderSVG /> :
          <ChartConfig chart={DTR_Graph.graphConfig} data={DTR_Graph.graphData} />
      }
      {/* <canvas ref={chartRef} id="weatherChart"></canvas> */}
      {/* : <div style={{  width: '75vw', display: 'flex', justifyContent: 'center', alignItems: 'center',fontSize:'20px'}}> Data Not Available</div> */}

      {/* } */}
    </div>
  );
};

export default WeatherChart;
