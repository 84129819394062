import React, { useEffect, useState } from 'react';
import windDirectionImg from '../../img/windDirection.svg';
import windImg from '../../img/wind.svg';
import gustImg from '../../img/gust.svg';
import uvImg from '../../img/uv.svg';
import illuminanceImg from '../../img/illuminance.svg';
import rainImg from '../../img/rain.svg';
import './WeatherBody.css'
import WeatherChart from '../WeatherChart';
import feelLike from '../../img/feelLike.svg'
import location from '../../img/location-pin.svg'
import liveHumidity from '../../img/humidity.svg'
import liveHumidity1 from '../../img/humidity1.svg'
import LoaderSVG from '../Loader/LoaderSVG';
import instance from '../../../../shared/utilities/axios';
import { getLegacyEventData, getPlatformEventData } from '../../../../shared/services/events';
import CustomSVG from '../CustomSVG';
import moment from 'moment';


function WeatherBody({ selectedId, selectedCity }) {
  const [weatherData, setWeatherData] = useState({
    feelsTemp: null,
    humidity: null,
    windDirection: null,
    windSpeed: null,
    realFeel: null,
    uvIndex: null,
    illuminance: null,
    localtime: null,
    rain: null,
    temperature: null,
    solar: null,
    gust: null,
    minMax: null
  });
  const [minMax, setMinMax] = useState(null)
  let ampm;
  let orgId = selectedId.split('_')[0]
  // Calculate "Feels Like" temperature (sample function)

  // function calculateFeelsLike(temperature, humidity) {
  //   //console.log(temperature, humidity)
  //   let numStr = humidity.toString().slice(0, -1);
  //   let num1 = parseInt(numStr, 10);
  //   let RH = num1 / 100;
  //   let feelsLike = (temperature + 0.33 * RH - 0.7).toFixed(2);
  //   //console.log(feelsLike)
  //   return feelsLike
  // }
  function calculateFeelsLike(temperature, humidity) {
    // Remove percentage sign if present and parse humidity
    let numStr = humidity.toString();
    let num1 = numStr.endsWith('%') ? numStr.slice(0, -1) : numStr;
    let RH = parseInt(num1, 10) / 100;

    if (isNaN(RH)) {
      return NaN;
    }

    let feelsLike = (temperature + 0.33 * RH - 0.7).toFixed(2);
    return feelsLike;
  }

  async function fetchLegacyData() {
    try {
      const response = await getLegacyEventData(orgId)
      if (!response) throw new Error('Network response was not ok');
      const data = response
      const updatedData = {
        feelsTemp: null,
        humidity: null,
        windDirection: null,
        windSpeed: null,
        realFeel: null,
        uvIndex: null,
        illuminance: null,
        rain: null,
        temperature: null,
        localtime: null,
        solar: null,
        gust: null,
        minMax: null

      };
      data.devices.forEach((device) => {
        device.events.forEach((event) => {
          const uniqueId = `${event.deviceId.split('_')[0]}_${event.deviceId.split('_')[1]}`;
          if (uniqueId === selectedId) {
            switch (event.type) {
              case 'Agri_Humidity_Status':
                updatedData.humidity = event.value;
                break;
              case 'Agri_WindDirection_Status':
                updatedData.windDirection = event.value;
                break;
              case 'Agri_WindSpeed_Status':
                updatedData.windSpeed = (event.value * 3.6).toFixed(2);
                break;
              case 'Agri_Illuminance_Status':
                updatedData.illuminance = event.value;
                break;
              case 'Agri_Gust_Status':
                updatedData.gust = event.value;
                break;
              case 'Agri_UV_Status':
                updatedData.uvIndex = event.value;
                break;
              case 'Agri_Rain_Status':
                updatedData.rain = event.value;
                break;
              case 'Agri_Temperature_Status':
                updatedData.temperature = event.value;

                const date = new Date(event.created);

                // Adjust time by subtracting 5 hours for UTC offset
                date.setUTCHours(date.getUTCHours() - 5);
                updatedData.localtime = date
                // Get today's and yesterday's dates at midnight
                const today = new Date();
                today.setHours(0, 0, 0, 0);

                const yesterday = new Date();
                yesterday.setDate(today.getDate() - 1);
                yesterday.setHours(0, 0, 0, 0);

                // Determine relative day label
                // let relativeDay;
                // if (date >= today) {
                //   relativeDay = "Today";
                // } else if (date >= yesterday) {
                //   relativeDay = "Yesterday";
                // } else {
                //   // Format for older dates
                //   relativeDay = date.toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' });
                // }

                // // Format time in hh:mm AM/PM format and set localtime
                // updatedData.localtime = `${relativeDay} at ${date.toLocaleTimeString([], {
                //   hour: '2-digit',
                //   minute: '2-digit',
                //   hourCycle: 'h12'
                // })}`;
                if (updatedData.humidity !== null) {
                  updatedData.feelsTemp = calculateFeelsLike(event.value, updatedData.humidity);
                }
                break;
              case 'Solar':
                updatedData.solar = event.value;
                break;
              default:
                break;
            }
          }
        });
      })
      setWeatherData(updatedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  function AddMinMax(e) {
    let data1 = { ...minMax }
    data1 = e
    setMinMax(data1)
  }

  async function fetchplatformData() {
    try {
      const response = await getPlatformEventData(orgId);
      if (!response) throw new Error('Network response was not ok');

      const data = response;
      const updatedData = {
        feelsTemp: null,
        humidity: null,
        windDirection: null,
        windSpeed: null,
        realFeel: null,
        uvIndex: null,
        illuminance: null,
        rain: null,
        temperature: null,
        solar: null,
        gust: null,
        minMax: null
      };
      data.devices.forEach((device) => {
        device.events.forEach((event) => {
          const uniqueId = `${event.deviceId.split('_')[0]}_${event.deviceId.split('_')[1]}`;
          if (uniqueId === selectedId) {

            switch (event.deviceId.split('_')[2]) {
              case 'Humidity':
                updatedData.humidity = event.value;
                break;
              case 'WindDirection':
                updatedData.windDirection = event.value;
                break;
              case 'WindSpeed':
                updatedData.windSpeed = event.value;
                break;
              case 'Illuminance':
                updatedData.illuminance = event.value;
                break;
              case 'Gust':
                updatedData.gust = event.value;
                break;
              case 'SolarRadiation':
                updatedData.uvIndex = event.value;
                break;
              case 'Rain':
                updatedData.rain = event.value;
                break;
              case 'Temperature':
                updatedData.temperature = event.value;
                const date = new Date(event.created);

                // Get today's and yesterday's dates at midnight
                const today = new Date();
                today.setHours(0, 0, 0, 0);

                const yesterday = new Date();
                yesterday.setDate(today.getDate() - 1);
                yesterday.setHours(0, 0, 0, 0);

                // Determine relative day label
                let relativeDay;
                if (date >= today) {
                  relativeDay = "Today";
                } else if (date >= yesterday) {
                  relativeDay = "Yesterday";
                } else {
                  // Format for older dates
                  relativeDay = date.toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' });
                }

                // Format time in hh:mm AM/PM format and set localtime
                updatedData.localtime = `${relativeDay} at ${date.toLocaleTimeString([], {
                  hour: '2-digit',
                  minute: '2-digit',
                  hourCycle: 'h12'
                })}`;
                if (updatedData.humidity !== null) {
                  updatedData.feelsTemp = calculateFeelsLike(event.value, updatedData.humidity);
                }
                break;
              case 'Solar':
                updatedData.solar = event.value;
                break;
              default:
                break;
            }
          }
        });
      })
      setWeatherData(updatedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  useEffect(() => {
    if (selectedCity.platform == "1.0") {
      fetchLegacyData();
      const intervalId = setInterval(fetchLegacyData, 60000);

      // Clear interval on component unmount
      return () => clearInterval(intervalId);
    } else {
      fetchplatformData();
      const intervalId = setInterval(fetchplatformData, 60000);

      // Clear interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [selectedCity]);

  function isDayTime() {
    let currentTime = new Date().getHours();
    ampm = currentTime >= 12 ? 'PM' : 'AM';
    return currentTime >= 6 && currentTime < 18;
  }
  useEffect(() => {
    isDayTime()
  }, [ampm])

  isDayTime()

  return (
    <>
      <div className="row weather__body" >
        <div className='col-12 col-md-6'>
          <div className="single_line">
            <img src={location} crossorigin="anonymous" alt="Location pin" />
            <div className="weather__city" >{selectedCity.name}</div>
          </div>
          {weatherData.temperature ?
            <div className="temp" >
              <span className="weather__temperature">{parseInt(weatherData.temperature)}</span>
              <span className="tempUnit">°C</span>
              <span className="verticalLine"> |</span>
              <span className="weather__datetime">{"Last update:  " + moment(weatherData.localtime).format("ll LTS")}</span>

              {/* <span className="weather_text_icon">{weatherData.current.condition.text}</span> */}
            </div>
            : <div style={{ width: '75vw', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '20px' }}> Data Not Available</div>}
        </div>
        <div className='col-12 col-md-6' style={{ display: "flex", justifyContent: "end", flexDirection: "column" }}>
          {/* <div className="weather__icon">
        <img id="weatherIcon" src={https:${weatherData.current.condition.icon}} alt={weatherData.current.condition.text} />
      </div> */}
          {minMax ?

            <div className='otherTemp mb-3'>
              <span style={{ color: "black", fontSize: "12px" }}>Last 24 Hours Minimum & Maximum Temperature:</span>
              <span>
                <span className="weather__feelslike">Min: {minMax.min.value}°C</span>
                {/* <img className="icon-size" src={feelLike} alt="Feels like" /> */}
              </span>
              <span>
                <span className="weather__feelslike">Max: {minMax.max.value}°C</span>
                {/* <img className="icon-size" src={feelLike} alt="Feels like" /> */}
              </span>
            </div>
            : ""}
          {weatherData.temperature ?
            <div className="otherTemp mb-3">
              <span className="liveIcon">
                {weatherData.feelsTemp ? <><span className="weather__feelslike">Feels Like: {Math.floor(weatherData.feelsTemp)}°C</span>
                  <img className="icon-size" crossorigin="anonymous" src={feelLike} alt="Feels like" /></> : ""}
              </span>
              <span className="liveIcon">
                <span className="weather__humidity">Humidity: {weatherData.humidity}%</span>
                <img className="icon-size" crossorigin="anonymous" src={liveHumidity} alt="Humidity" />
              </span>
            </div>
            : ""}


        </div>
      </div>
      {!weatherData.temperature ? <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div style={{ height: '100px', width: '100px', display: 'flex' }} ><LoaderSVG /></div></div>
        : <>

          <div className="weather__info">
            {weatherData.windDirection != null && (
              <div className="weather__card">
                <img className="img-size" src={windDirectionImg} crossorigin="anonymous" alt="Wind Direction" />
                <p>Wind Direction: <b>{weatherData.windDirection}</b> °</p>
              </div>
            )}
            {weatherData.windSpeed != null && (
              <div className="weather__card">
                <img className="img-size" src={windImg} crossorigin="anonymous" alt="Wind Speed" />
                <p>Wind Speed: <b>{weatherData.windSpeed}</b> km/h</p>
              </div>
            )}
            {weatherData.realFeel != null && (
              <div className="weather__card">
                <img className="img-size" src={gustImg} crossorigin="anonymous" alt="Real Feel" />
                <p>Real Feel: <b>{weatherData.realFeel}</b> °C</p>
              </div>
            )}
            {weatherData.uvIndex != null && (
              <div className="weather__card">
                <img className="img-size" src={uvImg} crossorigin="anonymous" alt="UV Index" />
                <p>Solar Radiation: <b>{weatherData.uvIndex}</b> W/m²</p>
              </div>
            )}
            {weatherData.illuminance != null && (
              <div className="weather__card">
                <img className="img-size" src={illuminanceImg} crossorigin="anonymous" alt="Illuminance" />
                <p>Illuminance:  <b>{weatherData.illuminance}</b> klux</p>
              </div>
            )}
            {weatherData.gust != null && (
              <div className="weather__card">
                <img className="img-size" src={gustImg} crossorigin="anonymous" alt="Gust" />
                <p>Gust: <b>{weatherData.gust}</b> m/s</p>
              </div>
            )}
            {weatherData.rain != null && (
              <div className="weather__card">
                <img className="img-size" src={rainImg} crossorigin="anonymous" alt="Rain" />
                <p>Rain: <b>{weatherData.rain}</b> mm</p>
              </div>
            )}
          </div>

          <WeatherChart minMax={(e) => AddMinMax(e)} selectedId={selectedId} selectedCity={selectedCity} />

          <div className='footer'>
            <div >Powered by</div>
            <div className='mt-2 '>
              <a href="http://linked-things.com/" target="_blank">
                <div className="logo ">
                  <CustomSVG />
                </div>
              </a>
            </div>
          </div>
        </>
      }


    </>

  )
}

export default WeatherBody