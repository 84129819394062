import { Map, InfoWindow, Marker, Polyline, GoogleApiWrapper } from 'google-maps-react';
import greenLogo from '../../assets/map/marker/Marker-1.png'
import React, { Component } from 'react';
import ReactDOM from "react-dom";
import * as functions from '../../functions';
import $ from 'jquery';
import { Card, Button, CardTitle, CardText, CardHeader, CardBody, Table, Row, Col } from 'reactstrap';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities'
import { Bar, Line } from 'react-chartjs-2';
import Loader from '../../components/loader1'


export class OverviewMap extends Component {
  constructor() {

    super();
    this.state = {
      showingInfoWindow: false,
      initialFetch: false,
      activeMarker: {},
      selectedPlace: {
        markerData: {
          name: null
        }

      },
      visible: true,
      boundsData: [
        { lat: 28.420703, lng: 69.710834 },
        { lat: 28.420703, lng: 69.710934 },
        { lat: 27.68026, lng: 68.845375 },
        { lat: 27.68024, lng: 68.845375 },
      ],
      bounds: [],
      mobileView: false,
      center: {
        lat: 28.420703,
        lng: 69.710834
      },
      data: null,
      google: null,
      devices: null,
      events: null,
      interval: null,
      filterHub: [],
      locations: [
        {
          lat: 28.420703,
          lng: 69.710834
        },
        {
          lat: 28.420703,
          lng: 69.710934
        },
        {
          lat: 27.68026,
          lng: 68.845375
        },
        {
          lat: 27.68024,
          lng: 68.845375
        }
      ],
    }
    this.fetchPlaces = this.fetchPlaces.bind(this);

  }

  onMarkerClick = (props, marker, e) => {
    if (this.state.mobileView) {
      this.setState({
        selectedPlace: props,
        activeMarker: marker,
        showingInfoWindow: true
      });
    }
  }
  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      })
    }
  };

  handleMouseOver = (props, marker, e) => {

    if (this.state.showingInfoWindow === false && this.state.mobileView === false) {
      this.setState({
        selectedPlace: props,
        activeMarker: marker,
        showingInfoWindow: true
      });
    }
  };
  handleMouseExit = (e) => {

    if (this.state.showingInfoWindow && this.state.mobileView === false) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      })
    }
  };
  onInfoWindowOpen(props, e) {
    if (this.state.mobileView === true) {
      const button = (
        <p
          style={{ textDecoration: 'underline', color: '#20a8d8' }}
        >
          View Details
        </p>
      );
    }
  }
  loadData = async () => {
    if (this.props.data !== null && this.state.data == null) {
      var org_name = 'UCPCASW';
      this.setState({
        data: this.props.data,
        devices: this.props.data.devices,
        events: this.props.data.events,
        filterHub: this.props.filterHub,
      })

      if (functions.isMobile.any() === null) {
        this.setState({
          mobileView: false
        })
      }
      else {
        this.setState({
          mobileView: true
        })
      }

      $(window).resize(() => {
        if (functions.isMobile.any() === null) {
          this.setState({
            mobileView: false
          })
        }
        else {
          this.setState({
            mobileView: true
          })
        }
      });

    }

  }

  async fetchPlaces(mapProps, map) {
    this.state.google = this.props.google;
    this.state.bounds = new this.props.google.maps.LatLngBounds();
    this.state.boundsData.map(ab => {
      this.state.bounds.extend(ab)
    })
    map.fitBounds(this.state.bounds);
    map.panToBounds(this.state.bounds);
  }

  setMarker = (level) => {
    // //console.log("level",level)
    let aqiDevice = null;
    let aqiValue = 0;
    if (this.state.devices !== null) {
      this.state.devices.forEach((i) => {

        if (i.levelId === level._id) {

          if (i.type === 'WaterTank') {
            aqiDevice = i
          }
        }
      })
      if (this.state.events.devices && aqiDevice !== null) {

        this.state.events.devices.forEach((e) => {
          if (aqiDevice._id === e._id) {
            e.events.forEach((x) => {
              if (x.type === 'Water_Status') {
                aqiValue = x.value
              }
            })
          }
        })


      }
      //"http://db.ottomatically.com/assets/img/marker-1.png"
      return greenLogo

    }
    else {
      return greenLogo
    }
  }





  render() {
    this.loadData()
    if (this.state.loading) {
      return (
        <Loader />
      );
    }
    else {
      return (


        <div className="p-0 m-0" style={{ height: 500 }}>
          <Map google={this.props.google}
            style={{ height: 500, width: '98%' }}
            maxZoom={19}
            onClick={this.onMapClicked}
            onReady={this.fetchPlaces}
            visible={this.state.visible}
          >
            {this.state.filterHub.map((value, index) => <Marker
              title={value.name}
              key={index}
              name={value.name}
              onClick={this.onMarkerClick}
              markerData={value}
              onMouseover={this.handleMouseOver} onMouseout={this.handleMouseExit}
              position={this.state.locations[index]}

              icon={{
                url: this.setMarker(value), //red
                scaledSize: new this.props.google.maps.Size(28, 43)
              }}

            />)}

            <InfoWindow
              marker={this.state.activeMarker}
              visible={this.state.showingInfoWindow}
              onOpen={e => {
                this.onInfoWindowOpen(this.state.selectedPlace);
              }}
            >

              <div>
                <h4>{this.state.selectedPlace.markerData.name}</h4>

                <div id="details" style={{ textAlign: "center", paddingLeft: 10, marginTop: 10 }} />
              </div>


            </InfoWindow>
          </Map>
        </div>


      );
    }
  }
}

export default GoogleApiWrapper({
  apiKey: ("AIzaSyA9osJ2QBSR6f9oBEZULavTAJzZxVccQMo")
})(OverviewMap);  