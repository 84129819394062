import React from 'react';

import { useEffect, useRef, useState } from "react";
import "./Weather.css";
import WeatherBody from "./WeatherBody/WeatherBody";
import sun from "../img/background-image/bg-sun.jpg";
// import partlyCloud from "../img/background-image/preetyDay.svg";
import night from "../img/background-image/bg-night3.jpg";
import rain from "../img/background-image/bg-rain.jpg";
import mist from "../img/background-image/bg-mist.jpg";
import domtoimage from 'dom-to-image';
import { toPng } from 'html-to-image';

import CustomSVG from './CustomSVG';
import LoaderSVG from '../Components/Loader/LoaderSVG';

function Weather() {
  // const [weatherData, setWeatherData] = useState(null);
  const [backgroundStyle, setBackgroundStyle] = useState({});
  const [mainStyle, setMainStyle] = useState({});
  const [isActive, setIsActive] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const containerRef = useRef(null);
  const bodyRef = useRef(null);
  const footerRef = useRef(null);
  const selectMenuRef = useRef(null);
  const buttonRef = useRef(null);

  const handleOptionClick = (optionText) => {
    setSelectedOption(optionText);
    setIsActive(false);
  };

  const [selectedCity, setSelectedCity] = useState({
    id: 'PashaFarm_2462abb929a',
    name: "Pasha Farms",
    platform: "1.0"


  });

  // const [selectedCity, setSelectedCity] = useState({
  //   id: 'LinkedThings_98cdac38406f',
  //   name: "Karachi ( Tariq Road )",
  //   platform: "2.0"

  // });

  const handleCityChange = (optionText) => {
    switch (optionText) {
      case 'Pasha Farms':
        setSelectedCity({
          id: 'PashaFarm_2462abb929a',
          name: "Pasha Farms",
          platform: "1.0"

        });
        break;
      case 'Rahuki Farms':
        setSelectedCity({
          id: 'PashaFarm_84f3ebaff9fe',
          name: "Rahuki Farms",
          platform: "1.0"
        });
        break;
      // case 'Karachi ( Pasha Farm )':
      //   setSelectedCity({
      //     id: 'PashaFarm_2462abb929a',
      //     name: "Karachi ( Pasha Farm )",
      //     platform: "1.0"

      //   });
      //   break;
      // case 'Tando Jam ( Pasha Farm )':
      //   setSelectedCity({
      //     id: 'PashaFarm_84f3ebaff9fe',
      //     name: "Tando Jam ( Pasha Farm )",
      //     platform: "1.0"
      //   });
      //   break;
      // case 'Jamshoro ( Mehran University )':
      //   setSelectedCity({
      //     id: 'USPCASW_483fda7446a1',
      //     name: "Jamshoro ( Mehran University )",
      //     platform: "2.0"
      //   });
      //   break;
      // case 'Hyderabad':
      //   setSelectedCity({
      //     id: 'SIDP_Unit1',
      //     name: "Hyderabad",
      //     platform: "2.0"
      //   }); 
      //   break;
      // case 'Thatta ( Keenjhar Lake )':
      //   setSelectedCity({
      //     id: 'SIDP_Unit4',
      //     name: "Thatta ( Keenjhar Lake )",
      //     platform: "2.0"
      //   });
      //   break;
      // case 'Naukot':
      //   setSelectedCity({
      //     id: 'SIDP_Unit6',
      //     name: "Naukot",
      //     platform: "2.0"
      //   });
      //   break;
      // case 'Sehwan ( Manchar Lake )':
      //   setSelectedCity({
      //     id: 'SIDP_Unit2',
      //     name: "Sehwan ( Manchar Lake )",
      //     platform: "2.0"
      //   });
      //   break;
      // case 'Hamal Lake':
      //   setSelectedCity({
      //     id: 'SIDP_Unit3',
      //     name: "Hamal Lake",
      //     platform: "2.0"
      //   });
      //   break;
      // case 'Dadu ( Gaaj nai )':
      //   setSelectedCity({
      //     id: 'SIDP_Unit5',
      //     name: "Dadu ( Gaaj nai )",
      //     platform: "2.0"
      //   });
      //   break;
      // case 'Karachi ( Karachi University )':
      //   setSelectedCity({
      //     id: 'KUAgriIoTeam_bcddc279ca45',
      //     name: "Karachi ( Karachi University )",
      //     platform: "2.0"
      //   });
      //   break;
      // Add more cities here as needed
      default:
        setSelectedCity({
          id: 'PashaFarm_2462abb929a',
          name: "Pasha Farm",
          platform: "1.0"
        });

      // id: 'LinkedThings_98cdac38406f',
      // name: "Karachi ( Tariq Road )",
      // platform: "2.0"
    }
  };
  const handleDownload = () => {
    const body = bodyRef.current
    const container = containerRef.current;
    const footer = footerRef.current;
    const selectMenu = selectMenuRef.current;
    const buttonR = buttonRef.current

    // // Save the original styles
    const oldBackground = container.style.background;
    const footerOldBackground = footer.style.backgroundColor;
    const oldFooterDisplay = footer.style.display;
    const oldselectMenu = selectMenu.style.display;


    // // Set new styles for download
    container.style.background = "#c0cbcdd2";
    footer.style.backgroundColor = "#203354";
    footer.style.display = 'block'
    selectMenu.style.display = 'none'
    buttonR.style.visibility = "hidden"

    // Capture the container as an image
    domtoimage.toPng(body)
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = "weather_card.png";
        link.click();

        // Revert to original styles
        container.style.background = oldBackground;
        footer.style.backgroundColor = footerOldBackground;
        footer.style.display = oldFooterDisplay
        selectMenu.style.display = oldselectMenu
        buttonR.style.visibility = 'visible'
      })
      .catch((error) => {
        console.error('Error capturing image:', error);
      });
  };
  // const handleDownload = () => {
  //   const body = bodyRef.current;
  //   const container = containerRef.current;
  //   const footer = footerRef.current;
  //   const selectMenu = selectMenuRef.current;
  //   const buttonR = buttonRef.current

  //   // // Save the original styles
  //   const oldBackground = container.style.background;
  //   const footerOldBackground = footer.style.backgroundColor;
  //   const oldFooterDisplay = footer.style.display;
  //   const oldselectMenu = selectMenu.style.display;
  //   const oldButton = buttonR.style.display

  //   if (!body) {
  //     console.error("Body reference not found!");
  //     return;
  //   }

  //   // Step 1: Replace all canvas elements with images
  //   const replaceCanvasWithImage = (node) => {
  //     const canvases = node.querySelectorAll("canvas");
  //     canvases.forEach((canvas) => {
  //       const img = document.createElement("img");
  //       img.src = canvas.toDataURL(); // Convert canvas to image
  //       img.style.width = canvas.style.width; // Preserve width
  //       img.style.height = canvas.style.height; // Preserve height
  //       img.crossOrigin = 'Anonymous';
  //       canvas.parentNode.replaceChild(img, canvas);
  //     });
  //   };

  //   // Step 2: Ensure external images are loaded
  //   const ensureImagesLoaded = async (node) => {
  //     const promises = [];
  //     node.querySelectorAll("img").forEach((img) => {
  //       if (!img.complete) {
  //         promises.push(
  //           new Promise((resolve) => {
  //             img.onload = resolve;
  //             img.onerror = resolve; // Resolve even if there's an error
  //           })
  //         );
  //       }
  //     });
  //     return Promise.all(promises);
  //   };

  //   // Step 3: Perform the replacement and generate the image
  //   ensureImagesLoaded(body)
  //     .then(() => {
  //       replaceCanvasWithImage(body); // Replace canvas with image
  //       return domtoimage.toPng(body); // Generate image with dom-to-image
  //     })
  //     .then((dataUrl) => {
  //       const link = document.createElement("a");
  //       link.href = dataUrl;
  //       link.download = "weather_card.png";
  //       link.click();

  //       // Revert to original styles
  //       container.style.background = oldBackground;
  //       footer.style.backgroundColor = footerOldBackground;
  //       footer.style.display = oldFooterDisplay
  //       selectMenu.style.display = oldselectMenu
  //       buttonR.style.display = oldButton
  //     })
  //     .catch((error) => {
  //       console.error("Error generating image:", error);
  //     });
  // };
  useEffect(() => {
    const currentHour = new Date().getHours(); // Get the current hour (0 - 23)

    if (currentHour >= 6 && currentHour < 18) {
      // Day time background
      setBackgroundStyle({
        // background: `url(${partlyCloud})`,
        backgroundSize: 'cover',
        background: "linear-gradient(to bottom, #87CEEB, #ffffff)",
        backgroundSize: 'cover',
      });

    } else {
      // Night time background
      setBackgroundStyle({
        // background: `url(${night})`,
        background: "linear-gradient(to bottom, #1e3c72, #2a5298)", // Sky blue to white gradient
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      });
      setMainStyle({
        background: 'rgba(215, 215, 215, 0.653)'
      })
    }
  }, []);

  useEffect(() => {

    handleCityChange(selectedOption)
  }, [selectedOption])


  // useEffect(() => {
  //   async function fetchWeatherData() {
  //     let weatherApiUrl = `https://gateway.s5.ottomatically.com/api/v1/forecast`;
  //     try {
  //       const response = await fetch(weatherApiUrl);
  //       if (!response.ok) throw new Error("Network response was not ok");

  //       const data = await response.json();
  //       setWeatherData(data);
  //       // Set the background based on the weather condition
  //       const condition = data.current.condition.text.toLowerCase();
  //       if (condition.includes("sunny")) {
  //         setBackgroundStyle({
  //           background: `url(${sun})`,
  //           backgroundColor: "#98FB98",

  //         });
  //       } else if (condition.includes("cloudy")) {
  //         setBackgroundStyle({
  //           background: `url(${partlyCloud})`,
  //           backgroundColor: "#203354",

  //         });
  //       } else if (condition.includes("rainy")) {
  //         setBackgroundStyle({
  //           background: `url(${rain})`,
  //           backgroundColor: "#333",

  //         });
  //       } else if (condition.includes("mist")) {
  //         setBackgroundStyle({
  //           background: `url(${mist})`,
  //           backgroundColor: "#5C4033",

  //         });
  //       } else {
  //         setBackgroundStyle({
  //           background: `url(${partlyCloud})`,
  //           backgroundColor: "#203354",

  //         });
  //       }
  //     } catch (error) {
  //       console.error("Error fetching weather data:", error);
  //     }
  //   }

  //   fetchWeatherData();
  //   const intervalId = setInterval(fetchWeatherData, 60000);

  //   // Clear interval on component unmount
  //   return () => clearInterval(intervalId);
  // }, [selectedCity]);

  // if (!weatherData) return <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div style={{ height: '100px', width: '100px', display: 'flex' }} ><LoaderSVG /></div></div>;
  const handleClickOutside = (event) => {
    if (selectMenuRef.current && !selectMenuRef.current.contains(event.target)) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Remove event listener when the component unmounts
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div ref={bodyRef} className="body position-relative" style={backgroundStyle}>

      <div ref={containerRef} className="main" style={mainStyle}>
        <div ref={buttonRef} className="download-btn" onClick={handleDownload}>
          <div className="button-wrapper">
            <span className="icon">
              <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="2em" height="2em" viewBox="0 0 24 24">
                <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 15V3m0 12l-4-4m4 4l4-4M2 17l.621 2.485A2 2 0 0 0 4.561 21h14.878a2 2 0 0 0 1.94-1.515L22 17" />
              </svg>
            </span>
          </div>
        </div>
        <div ref={selectMenuRef} className={`select-menu ${isActive ? 'active' : ''}`}>
          <div className="select-btn" onClick={() => setIsActive(!isActive)}>
            <span className="sBtn-text">{selectedCity?.name ? selectedCity.name : "Select City"}</span>
            <i className="bx bx-chevron-up"></i>
          </div>

          <ul className="options">
            <li className="option" onClick={() => handleOptionClick('Pasha Farms')}>
              <span className="option-text">Pasha Farms</span>
            </li>
            <li className="option" onClick={() => handleOptionClick('Rahuki Farms')}>
              <span className="option-text">Rahuki Farms</span>
            </li>


            {/* <li className="option" onClick={() => handleOptionClick('Karachi ( Tariq Road )')}>
              <span className="option-text">Karachi ( Tariq Road )</span>
            </li> */}
            {/* <li className="option" onClick={() => handleOptionClick('Karachi ( Karachi University )')}>
              <span className="option-text">Karachi ( Karachi University )</span>
            </li> */}
            {/* <li className="option" onClick={() => handleOptionClick('Karachi ( Pasha Farm )')}>
              <span className="option-text">Karachi ( Pasha Farm )</span>
            </li>
            <li className="option" onClick={() => handleOptionClick('Tando Jam ( Pasha Farm )')}>
              <span className="option-text">Tando Jam ( Pasha Farm )</span>
            </li> */}
            {/* <li className="option" onClick={() => handleOptionClick('Jamshoro ( Mehran University )')}>
              <span className="option-text">Jamshoro ( Mehran University )</span>
            </li>
            <li className="option" onClick={() => handleOptionClick('Hyderabad')}>
              <span className="option-text">Hyderabad</span>
            </li>
            <li className="option" onClick={() => handleOptionClick('Thatta ( Keenjhar Lake )')}>
              <span className="option-text">Thatta ( Keenjhar Lake )</span>
            </li>
            
            <li className="option" onClick={() => handleOptionClick('Naukot')}>
              <span className="option-text">Naukot</span>
            </li>
            <li className="option" onClick={() => handleOptionClick('Sehwan ( Manchar Lake )')}>
              <span className="option-text">Sehwan ( Manchar Lake )</span>
            </li>
            <li className="option" onClick={() => handleOptionClick('Hamal Lake')}>
              <span className="option-text">Hamal Lake</span>
            </li>
            <li className="option" onClick={() => handleOptionClick('Dadu ( Gaaj nai )')}>
              <span className="option-text">Dadu ( Gaaj nai )</span>
            </li> */}



          </ul>
        </div>

        <WeatherBody selectedId={selectedCity.id} selectedCity={selectedCity} />
      </div>

      <div ref={footerRef} className="footer">
      </div>
    </div>
  );
}

export default Weather;
