import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Maps from "./maps/maps";
import RequestInfo from "./strip/request-info";
import AqiTable from "./tables/aqiTable";
import { getAqiDevices } from "../shared/services/events";
import DecriptionBelow from "./aq-desc/desc-below";
import Header from "../components/header/header";
import MinMaxBar from "./graphs/barGraph";
import Loader from "../components/loader1";
import _ from "lodash";

let levelId = "",
  locationTitleCase = "",
  mapCoordinates;
class AqContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aqiDevices: null,
      dustDevices: null,
      filterHub: null,
      coordinate: { lat: 30.2919945, lng: 70.305532 },
      indivAQI: null
    };
    levelId = "";
    locationTitleCase = "";
  }
  titleCase = (str) => {
    return str.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase());
  };
  componentDidMount() {
    const locationName = this.props.match.path.split('/')[1];
    this.aqiLevelClick(locationName);
  }

  async aqiLevelClick(location) {
    this.setState({
      aqiDevices: null,
      dustDevices: null,
      filterHub: null,
    });
    if (location) {
      locationTitleCase = this.titleCase((location || "Air Quality"));
    }
    document.getElementById("root_title").innerHTML =
      "Linked Things - " + locationTitleCase;

    if (location === "karachi") {
      mapCoordinates = { lat: 24.891465, lng: 67.081024 };
    } else if (location === "lahore") {
      mapCoordinates = { lat: 31.4979993, lng: 74.3397544 };
    } else if (location === "islamabad") {
      mapCoordinates = { lat: 33.621155, lng: 72.995002 };
      // locationTitleCase = this.titleCase("Peshawar");
    } else if (location === "peshawar") {
      mapCoordinates = { lat: 34.015802, lng: 71.589388 };
      // locationTitleCase = this.titleCase("Islamabad");
    } else {
      mapCoordinates = { lat: 30.2919945, lng: 70.305532 };
    }
    let aqiDevices1 = await getAqiDevices(
      "Karachi",
      locationTitleCase !== "" && locationTitleCase !== "Air Quality" ? locationTitleCase : "AQI"
    );
    let aqiDevices2 = await getAqiDevices(
      "Lahore",
      locationTitleCase !== "" && locationTitleCase !== "Air Quality" ? locationTitleCase : "AQI"
    );
    let aqiDevices3 = await getAqiDevices(
      "Islamabad",
      locationTitleCase !== "" && locationTitleCase !== "Air Quality" ? locationTitleCase : "AQI"
    );
    let aqiDevices4 = await getAqiDevices(
      "Peshawar",
      locationTitleCase !== "" && locationTitleCase !== "Air Quality" ? locationTitleCase : "AQI"
    );
    let indivAQI = { Karachi: aqiDevices1, Lahore: aqiDevices2, Islamabad: aqiDevices3, Peshawar: aqiDevices4 }
    let aqiDevices = [...aqiDevices1, ...aqiDevices2, ...aqiDevices3, ...aqiDevices4]
    let dustDevicesArr = [];
    let aqiDevicesArr = [];

    let dustSplit = null;
    if (aqiDevices !== "error") {
      aqiDevices.sort((a, b) => (a.event.value < b.event.value ? 1 : -1));
      let dustDevices1 = await getAqiDevices(
        "Karachi",
        "Dust"
      );
      let dustDevices2 = await getAqiDevices(
        "Lahore",
        "Dust"
      );
      let dustDevices3 = await getAqiDevices(
        "Islamabad",
        "Dust"
      );
      let dustDevices4 = await getAqiDevices(
        "Peshawar",
        "Dust"
      );
      let dustDevices = [...dustDevices1, ...dustDevices2, ...dustDevices3, ...dustDevices4]
      aqiDevices.map((device) => {
        dustDevices.map((dust) => {
          if (device.hubId === dust.hubId) {
            dustSplit = dust.event.deviceId.split("_");
            if (dustSplit[2] == "Dust" || dustSplit[2] == "Dust2.5") {
              device["dust"] = dust.event.deviceId;
            }
          }
        });
      });
      aqiDevices.map((aqi) => {
        if (!_.get(aqi, "level[0].metadata.indoor")) {
          aqiDevicesArr.push(aqi);
        }
      });
      aqiDevices = aqiDevicesArr;
      dustDevices.map((dust) => {
        if (!_.get(dust, "level[0].metadata.indoor")) {
          dustDevicesArr.push(dust);
        }
      });
      dustDevices = dustDevicesArr;
      if (dustDevices !== "error") {
        this.setState({
          aqiDevices,
          dustDevices,
          indivAQI
        });
      }
    }
  }
  mapNavigation = (coord) => {
    this.setState({ coordinate: coord })
  }
  render() {
    return (
      <React.Fragment>
        {/* <Header /> */}
        {/* <Description /> */}
        <div className="row mx-0 rounded mb-5 dropshadow justify-content-center  ">
          <div
            className="col-12 pb-0 px-1 dropshadow row m-0 align-items-center"
            style={{ borderRadius: "30px" }}
          >
            <div className="col-2 m-1 p-0" >
              <select
                className="form-select"
                onChange={(e) => this.aqiLevelClick(e.target.value)}
                style={{
                  borderRadius: "50px",
                  cursor: "pointer",
                }}
                value={locationTitleCase}
              >
                <option value="" disabled>Select a Measurement</option>
                <option value="Air Quality">AQI</option>
                <option value="Temperature">Temperature</option>
                <option value="Humidity">Humidity</option>
              </select>
            </div>
            <div className="col-9">

              <Header />
            </div>
            {/* <div className="col-xl-1 col-lg-1 col-md-3 col-sm-4 col-6 mb-2 px-1">
              <a
                href="#/"
                onClick={() => this.aqiLevelClick("Air Quality")}
                className={
                  locationTitleCase === "" ||
                  locationTitleCase === "Air Quality"
                    ? "btn w-100 gradient-primary1 text-light"
                    : "btn w-100 btn-light-grey"
                }
                style={{ borderRadius: "50px" }}
              >
                AQI
              </a>
              </div>
              <div className="col-xl-1 col-lg-1 col-md-3 col-sm-4 col-6 mb-2 px-1">
              <a
                href="#/temperature"
                onClick={() => this.aqiLevelClick("temperature")}
                className={
                  locationTitleCase === "Temperature"
                    ? "btn w-100 gradient-primary1 text-light"
                    : "btn w-100 btn-light-grey"
                }
                style={{ borderRadius: "50px" }}
              >
                Temperature
              </a>
              
              </div>
              <div className="col-xl-1 col-lg-1 col-md-3 col-sm-4 col-12 mb-2 px-1">
                <a
                  href="#/humidity"
                  onClick={() => this.aqiLevelClick("humidity")}
                  className={
                    locationTitleCase === "Humidity"
                      ? "btn w-100 text-truncate gradient-primary1 text-light"
                      : "btn w-100 text-truncate btn-light-grey"
                  }
                  style={{ borderRadius: "50px" }}
                >
                  Humidity 
                </a>
              </div> */}

          </div>
          <div className="col-12 col-lg px-0">
            {this.state.aqiDevices ? (
              <Maps
                aqiDevices={this.state.aqiDevices}
                indivAQI={this.state.indivAQI}
                key={locationTitleCase}
                location={this.state.coordinate}
                locationTitleCase={locationTitleCase}
                zoom={6}
              // zoom={locationTitleCase === "Lahore"? 10 : 11}
              />
            ) : (
              <Loader />
            )}
          </div>
          {/* {locationTitleCase === "" || locationTitleCase === "Air Quality"? */}
          <div className="col-xl-4 col-lg-5 p-0" style={{ maxWidth: "400px" }}>
            {this.state.aqiDevices && this.state.dustDevices ? (
              <AqiTable
                aqiDevices={this.state.aqiDevices}
                dustDevices={this.state.dustDevices}
                mapNavigation={(e) => this.mapNavigation(e)}
                locationTitleCase={locationTitleCase}
              />
            ) : (
              <Loader />
            )}
          </div>
          {/* :null} */}
        </div>
        {locationTitleCase === "" || locationTitleCase === "Air Quality" ?
          <div className="row mx-0">
            <div className="col-12 col-lg-6 mb-3">
              <MinMaxBar
                _id={"Karachi"}
                type="Level"
                key={"Karachi"}
                title={"Daily Max and Min AQI " + ("Karachi")}
              />
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <MinMaxBar
                _id={"Lahore"}
                type="Level"
                key={"Lahore"}
                title={"Daily Max and Min AQI " + ("Lahore")}
              />
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <MinMaxBar
                _id={"Islamabad"}
                type="Level"
                key={"Islamabad"}
                title={"Daily Max and Min AQI " + ("Islamabad")}
              />
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <MinMaxBar
                _id={"Peshawar"}
                type="Level"
                key={"Peshawar"}
                title={"Daily Max and Min AQI " + ("Peshawar")}
              />
            </div>
            {/* ) : ( */}
            {/* <Loader /> */}
            {/* )} */}

          </div>
          : null}
        {/* <div
          id="location_graphs"
          className="col-12 p-0 gradient-primary2 mt-5 mb-3 py-4 text-light text-center"
          style={{ fontSize: "18px" }}
        >
          <b>Max and Min AQI by Location</b>
        </div> */}
        {/* <div className="row">
            {this.state.aqiDevices ? (
              this.state.aqiDevices.map((device) => (

                <Col
                  key={device._id}
                  sm="12"
                  md="6"
                  lg="4"
                  xs="12"
                  xl="4"
                  className="m-0 p-sm-3 p-0"
                >
                  <div className="dropshadow m-3">
                    <MinMaxBar
                      _id={device._id}
                      type="Device"
                      title={device.level[0].name}
                    />
                  </div>
                </Col>
              ))
            ) : (
              <Loader />
            )}
          </div> */}

        <RequestInfo />
        <div className="container py-5">
          <DecriptionBelow />
        </div>
        {/* <Footer /> */}
      </React.Fragment>
    );
  }
}

export default withRouter(AqContainer);
